$(document).ready(function() {
	/* menu */
	$('.menu').on('click', function() {
		$('#sidebar').toggleClass('active');
	});
	/* load function */
	if('.region-map'){
	archivSlider('.region-map', 3000);
	}
	/* maps menu */
	var mapsMenu = '.maps-menu>ul';
	$(mapsMenu + ' li').on('click', function(){
		$(mapsMenu).find('.active').removeClass("active");
		$(this).addClass("active");
		var imgId = $(this).attr('data');
		$(".abs").find('.active').animate({
			opacity: 0,
		}, 200, function(){
			$(this).removeClass("active");
			$("#" + imgId).addClass("active").animate({
				opacity: 1,
			}, 800);
		});
		
		
	});
	/* lightbox */
	lightbox.option({
      'showImageNumberLabel': false,
    })

    /* question open form*/
    $("#open-form").parent().on('click', '#open-form', function(){
    	$("#add-question-form").fadeIn(800);
    	$("#open-form").text("Скрыть");
    	$("#open-form").attr("id", "close-form");
    });

    $("#open-form").parent().on('click', '#close-form', function () {
	    $("#add-question-form").fadeOut(800);
	    $("#close-form").text("Задать вопрос");
	    $("#close-form").attr("id", "open-form");    
    });

    /* slick slider */
    
    	$('.parthner-slider').slick({
    		infinite: true,
    		autoplay: true,
	      	autoplaySpeed: 3000,
	      	dots: false,
	      	arrows: false,
	      	slidesToShow: 2,
  			slidesToScroll: 1,
  			responsive: [
			    {
			      breakpoint: 600,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
			    }
			    
			]
	    });
   

});