/* slider region map */
    function archivSlider(cSlider, cTimeOut){
      var kSlider ={
        SlideSpeed : 1000,
        TimeOut : 11000,
        sNum: 0,
        tSlider: ".slider .slide",
      };
      kSlider.tSlider=cSlider+" .slide";
      kSlider.TimeOut=cTimeOut;
      if(kSlider.sNum == 0){
          $(kSlider.tSlider).eq(0).css("display", "block");
          //kSlider.sNum ++;
          setTimeout(minus, kSlider.TimeOut);
      }

      function plus(){
      $(kSlider.tSlider).eq(kSlider.sNum).fadeIn(kSlider.SlideSpeed);
      setTimeout(minus, kSlider.TimeOut);  
      }
            
      function minus(){
       $(kSlider.tSlider).eq(kSlider.sNum).fadeOut(kSlider.SlideSpeed);
       var countSlide=$(kSlider.tSlider).length - 1;
       if(kSlider.sNum < countSlide){
              kSlider.sNum ++;
              setTimeout(plus(), kSlider.TimeOut);
            }
            else{
            kSlider.sNum=0; 
            setTimeout(plus, 1); 
            } 
      }     
    };